<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Survey">
                <BasicOverview v-if="!editEnabled" :data="survey" :definitions="overviewDefinitions"/>
                <div v-if="!editEnabled">
                    <div v-for="(question, index) of survey.questions" :key="index">
                        <hr>
                        <BasicOverview :data="question" :definitions="overviewDefinitionsQuestions"/>
                    </div>
                </div>
                <div v-else>

                    <b-form-group>
                        <label>Enabled</label>
                        <b-form-checkbox v-model="editObject.enabled" switch>Enabled</b-form-checkbox>
                    </b-form-group>

                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="editObject.name"/>
                    </b-form-group>

                    <b-form-group>
                        <label>Type</label>
                        <v-select
                            v-model="editObject.type"
                            :reduce="type => type.id"
                            label="name"
                            :options="[{id: 0, name: 'Outlet survey'}, {id: 1, name: 'Promotional item survey'}]"
                        />
                    </b-form-group>
                </div>

                <hr>
                <template v-if="!editEnabled">
                    <b-button v-if="$hasPermission($permissions.EditSurveys)" variant="warning" @click="openEdit" class="mr-1">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">Edit</span>
                    </b-button>
                    <b-button @click="onExportToExcel" class="mr-1">
                        <span class="align-middle">Export to Excel</span>
                    </b-button>
                </template>

                <div v-if="editEnabled">
                    <h3>Questions</h3>
                    <hr/>
                    <div v-for="(question, index) in editObject.questions" :key="index">
                        <b-row>
                            <b-col cols="9" sm="5">
                                <b-form-group >
                                    <label>Type</label>
                                    <v-select
                                        v-model="editObject.questions[index].type"
                                        :reduce="type => type.id"
                                        label="name"
                                        :options="types"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="2" sm="7" align="right">
                                <b-button variant="danger" @click="editObject.questions.splice(index, 1)" class="mr-1 mt-2">
                                    <feather-icon icon="Trash2Icon"/>
                                </b-button>
                            </b-col>
                        </b-row>

                        <div v-if="editObject.questions[index].type === 1 || editObject.questions[index].type === 4">
                            <b-row>
                                <b-col>
                                    <b-form-group>
                                        <label>Question</label>
                                        <b-form-input :value="editObject.questions[index].question" v-on:input="(value) => editObject.questions[index].question = value"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-form-group>
                                <label>Options</label>
                                <div v-for="(option, index1) in editObject.questions[index].options" :key="index1">
                                    <b-row>
                                        <b-col cols="9" sm="5">
                                            <b-form-group>
                                                <b-form-input :value="editObject.questions[index].options[index1]" v-on:input="(value) => editObject.questions[index].options[index1] = value"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="2" sm="2">
                                            <b-button variant="danger" @click="editObject.questions[index].options.splice(index1, 1)" class="mr-1">
                                                <feather-icon icon="Trash2Icon"/>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-form-group>

                            <b-form-group>
                                <b-button variant="primary" @click="editObject.questions[index].options.push('')">
                                    <feather-icon
                                        icon="PlusIcon"
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Add option</span>
                                </b-button>
                            </b-form-group>
                        </div>

                        <div v-else>
                            <b-row>
                                <b-col>
                                    <b-form-group>
                                        <label>Question</label>
                                        <b-form-input :value="editObject.questions[index].question" v-on:input="(value) => editObject.questions[index].question = value"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <hr class="mb-3"/>
                    </div>

                    <b-form-group>
                        <b-button variant="primary" @click="editObject.questions.push({question: '', options: [], type: 0})">
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Add question</span>
                        </b-button>
                    </b-form-group>
                </div>
                <div v-if="editEnabled">
                    <hr class="mt-3"/>
                    <template>
                        <b-button variant="success" @click="saveSurvey" class="mr-1">
                            <feather-icon icon="SaveIcon" class="mr-50"/>
                            <span class="align-middle">Save</span>
                        </b-button>
                        <b-button variant="danger" @click="closeEdit">
                            <feather-icon icon="XIcon" class="mr-50"/>
                            <span class="align-middle">Cancel</span>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveSurveys)" variant="danger" @click="removeSurvey" class="float-right mr-1">
                            <feather-icon icon="Trash2Icon" class="mr-50"/>
                            <span class="align-middle">Remove</span>
                        </b-button>
                    </template>
                </div>
            </b-card>

            <SurveyResults :data-url="`/api/management/v1/survey/${  $route.params.id}/results`"/>

           
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BFormCheckbox, BOverlay, BRow} from 'bootstrap-vue'
    import BasicOverview from '@/views/components/BasicOverview'
    import vSelect from 'vue-select'
    import SurveyResults from '@/views/components/SurveyResults'
    
    export default {
        components: {
            SurveyResults,
            BasicOverview,
            vSelect,
            BCard,
            BOverlay,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormCheckbox
        
        },
        data() {
            return {
                dataLoaded: false,
                survey: {
                    name: '',
                    enabled: false,
                    type: 0,
                    questions: []
                },
                types: [{id: 0, name:'Single answer question (Yes/No)'}, {id: 1, name:'Multiple answer question'}, {id: 2, name:'Free text question'}, {id: 3, name:'Number question'}, {id: 4, name:'Multiple answer question - select multiple'}, {id: 5, name:'Date question'}, {id: 6, name:'Date and time question'}],
                overviewDefinitions: [
                    { key: 'name', name: 'Name', type: 0 },
                    { key: 'enabled', name: 'Enabled', type: 1 },
                    { key: 'type', name: 'Type', type: 0, formatFn: (data) => ([{id: 0, name: 'Outlet survey'}, {id: 1, name: 'Promotional item survey'}][data] || {name: 'Unknown'}).name}
                ],
                overviewDefinitionsQuestions: [
                    { key: 'type', name: 'Type', type: 0, formatFn: (data) => (this.types.find(type => type.id === data).name) },
                    { key: 'question', name: 'Question', type: 0 },
                    { key: 'options', name: 'Options', type: 0, formatFn: (data) => ((data === null || data.length === 0) ? '/' : data.join(', ')) }
                ],
                editEnabled: false,
                editObject: {}
            }
        },
        methods: {
            onExportToExcel() {
                window.open(`/api/management/v1/export/survey/${this.$route.params.id}`)
            },
            openEdit() {
                this.editObject = JSON.parse(JSON.stringify(this.survey))
                this.editEnabled = true
            },
            closeEdit() {
                this.editEnabled = false
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/survey/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.survey = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveSurvey() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/survey/${  this.$route.params.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Survey saved')
                    thisIns.editEnabled = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removeSurvey() {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this survey?')

                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/survey/${  this.$route.params.id}`)
                    this.$printSuccess('Survey removed')
                    this.$router.go(-1)

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
