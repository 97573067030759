<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Survey Results">
                <basic-table :columns="columns" :data="formattedResults" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="openSurveyResult(props.row)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveSurveyResults) && showDelete" variant="danger" @click="removeSurveyResult(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <span>{{moment(props.row.time).format('YYYY/MM/DD HH:mm')}}</span>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Result" v-model="surveyResultModalActive" no-close-on-backdrop>
            <template v-if="resultsObject.results" #default>
                <div v-for="(result, index) of resultsObject.results" :key="index">
                    <hr v-if="index !== 0">
                    <BasicOverview :data="result" :definitions="overviewDefinitionsResults"/>
                </div>
            </template>
            <template #modal-footer>
                <b-button variant="danger" @click="surveyResultModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import {BButton, BCard, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import BasicOverview from '@/views/components/BasicOverview'

    export default {
        components: {
            BasicOverview,
            BasicTable,
            BCard,
            BOverlay,
            BButton,
            BModal
        },
        props: {
            dataUrl: {
                type: String,
                required: true
            },
            dateFrom: {
                type: Date,
                dafault: ''
            },
            dateTo: {
                type: Date,
                dafault: ''
            },
            addDateToUrl:{
                type: Boolean,
                default: false
            },
            showUser:{
                type: Boolean,
                default: true
            },
            showDelete:{
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                dataLoaded: false,
                surveyResults: [],

                columns: [
                    {
                        label: 'Survey',
                        displayType: 0,
                        field: 'survey',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Outlet/Promotional item',
                        displayType: 0,
                        field: 'outlet_promotional_item',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Time',
                        displayType: 2,
                        field: 'time',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                resultsObject: {},
                surveyResultModalActive: false,
                overviewDefinitionsResults: [
                    { key: 'question', name: 'Question', type: 0 },
                    { key: 'answer', name: 'Answer', type: 0 }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                let url = this.dataUrl
        

                if (this.addDateToUrl) {

                    if (!this.dateFrom || !this.dateTo || this.dateFrom === '' || this.dateTo === '') {
                        this.dataLoaded = true
                        return
                    }

                    const parsedDateFrom = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const parsedDateTo = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    url += `?date_from=${parsedDateFrom}&date_to=${parsedDateTo}`
                }

                const resultsPromise = this.$http.get(url)
                resultsPromise.then(function(response) {
                    thisIns.surveyResults = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeSurveyResult(id) {
                const thisIns = this
                const removeResultPromise = this.$http.delete(`/api/management/v1/survey_result/${  id}`)
                removeResultPromise.catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            openSurveyResult(object) {
                this.resultsObject = object
                this.surveyResultModalActive = true
            }
        },
        computed: {
            formattedResults() {
                return this.surveyResults.map(result => {
                    return {
                        id: result.id,
                        survey: result.survey,
                        time: result.time,
                        outlet_promotional_item: (result.outlet.length > 0) ? result.outlet : result.promotional_item_serial_number,
                        user_id: result.user_id,
                        username: result.username,
                        user_name: result.user_name,
                        user_last_name: result.user_last_name,
                        results: result.results
                    }
                })
            }
        },
        watch: {
            dateFrom() {
                this.loadData()
            },
            dateTo() {
                this.loadData()
            }
        },
        mounted() {

            if (this.showUser) {
                const userNameItem = {
                    label: 'User name',
                    displayType: 0,
                    field: 'user_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search'
                    }
                }

                const userLastNameItem = {
                    label: 'User last name',
                    displayType: 0,
                    field: 'user_last_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Search'
                    }
                }

                this.columns.splice(2, 0, userNameItem)
                this.columns.splice(3, 0, userLastNameItem)

            
            }

            this.loadData()
        }
    }
</script>